import StorageService from "@/services/storage.service";

class AnnouncementStage {
  id: number;
  name: string;
  cover_photo: string;
  thumbnail_photo: string;

  constructor(data: Record<string, any>) {
    this.id = data.id;
    this.name = data.name;
    this.cover_photo = data.cover_photo;
    this.thumbnail_photo = data.thumbnail_photo;
  }
}

class AnnouncementShowroom {
  id: number;
  name: string;
  cover_photo: string;
  thumbnail_photo: string;

  constructor(data: Record<string, any>) {
    this.id = data.id;
    this.name = data.name;
    this.cover_photo = data.cover_photo;
    this.thumbnail_photo = data.thumbnail_photo;
  }
}

class AnnouncementSession {
  id: number;
  name: string;
  cover_photo: string;
  thumbnail_photo: string;

  constructor(data: Record<string, any>) {
    this.id = data.id;
    this.name = data.name;
    this.cover_photo = data.cover_photo;
    this.thumbnail_photo = data.thumbnail_photo;
  }
}

enum AnnouncementTypes {
  NO_ACTION = "NO_ACTION",
  ALL_STAGES = "ALL_STAGES",
  ALL_SHOWROOMS = "ALL_SHOWROOMS",
  ALL_SESSIONS = "ALL_SESSIONS",
  STAGE = "STAGE",
  SHOWROOM = "SHOWROOM",
  SESSION = "SESSION",
  CUSTOM = "CUSTOM",
}

export default class Announcement {
  id: number;
  conference: number;
  visible: boolean;
  title: string;
  description: string;
  cta_type: AnnouncementTypes;
  cta_text: string;
  expires_at: Date;
  expires_in: number;
  stage?: AnnouncementStage;
  showroom?: AnnouncementShowroom;
  session?: AnnouncementSession;

  custom_url?: string;
  custom_image?: string;

  constructor(data: Record<string, any>) {
    this.id = data.id;
    this.conference = data.conference;
    this.visible = this.visibleFromCookie();
    this.title = data.title;
    this.description = data.description;
    this.cta_type = data.cta_type;
    this.cta_text = data.cta_text;
    this.expires_at = new Date(data.expires_at);
    this.expires_in = this.expires_at.getTime() - Date.now();
    if (data.stage) this.stage = new AnnouncementStage(data.stage);
    if (data.showroom) this.showroom = new AnnouncementShowroom(data.showroom);
    if (data.session) this.session = new AnnouncementSession(data.session);

    this.custom_image = data.custom_image;
    this.custom_url = data.custom_url;
  }

  objectId() {
    console.log(this.cta_type, this.cta_type == AnnouncementTypes.STAGE, AnnouncementTypes.STAGE, this.stage);
    if (this.cta_type == AnnouncementTypes.STAGE) return this.stage.id;
    if (this.cta_type == AnnouncementTypes.SESSION) return this.session.id;
    if (this.cta_type == AnnouncementTypes.SHOWROOM) return this.showroom.id;
    if (this.cta_type == AnnouncementTypes.CUSTOM) return this.custom_url;
    return null;
  }

  hide() {
    this.visible = false;
    this.visibleToCookie();
  }

  private visibleToCookie() {
    const cookieString = StorageService.get("confiva_announcements");
    let cookie = [0];
    if (cookieString) {
      cookie = JSON.parse(cookieString);
    }
    cookie.push(this.id);
    StorageService.save("confiva_announcements", JSON.stringify(cookie));
  }

  private visibleFromCookie(): boolean {
    const cookieString = StorageService.get("confiva_announcements");
    if (cookieString) {
      const cookies = JSON.parse(cookieString);
      if (cookies.findIndex((c) => c == this.id) >= 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}

export { AnnouncementStage, AnnouncementShowroom, AnnouncementSession, AnnouncementTypes };
