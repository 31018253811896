import NotificationModel from "@/models/notification.model";
import NotificationsService from "@/services/api/notifications.service";
import StorageService from "@/services/storage.service";
import Store from "@/store";
import Router from "@/router";
import Vue from "vue";
import CommonService from "@/services/common.service";
import Announcement, { AnnouncementTypes } from "@/models/announcement.model";

interface NotificationsStoreI {
  notifications: Array<NotificationModel>;
  lastNotificationSeek: Date | null;
  unreadNotifications: Array<any>;
  popupNotifications: Array<any>;
  notificationsLoading: boolean | null;
  notificationsUnreadLoading: boolean | null;
  notificationsNextPage: number | null;
  enabledPopupNotifications: boolean;
}

const state: NotificationsStoreI = {
  notifications: [],
  popupNotifications: [],
  unreadNotifications: [],
  notificationsLoading: null,
  notificationsUnreadLoading: null,
  notificationsNextPage: 2,
  enabledPopupNotifications: StorageService.get("notifications-popup-disabled") ? false : true,
  lastNotificationSeek: StorageService.get("last-notifications-seek")
    ? new Date(parseInt(StorageService.get("last-notifications-seek")))
    : null,
};
const getters = {
  popupNotifications(state) {
    // return state.notifications.slice(0, 3);
    if (!state.enabledPopupNotifications) return [];

    return state.notifications
      .filter((x) => state.popupNotifications.includes(x.id) && state.unreadNotifications.includes(x.id))
      .slice(0, 3);
  },

  unreadNotificationCount(state) {
    return state.notifications.filter(
      (x) => state.unreadNotifications.includes(x.id) && (state.lastNotificationSeek == null || x.updated >= state.lastNotificationSeek)
    ).length;
  },

  allUnreadNotifications(state) {
    return state.notifications.filter((x) => state.unreadNotifications.includes(x.id));
  },
};
const mutations = {
  cleanStore(state: NotificationsStoreI) {
    state.notifications = [];
    state.popupNotifications = [];
    state.unreadNotifications = [];
    state.notificationsLoading = null;
    state.notificationsUnreadLoading = null;
    state.notificationsNextPage = 2;
    state.enabledPopupNotifications = StorageService.get("notifications-popup-disabled") ? false : true;
    state.lastNotificationSeek = StorageService.get("last-notifications-seek")
      ? new Date(parseInt(StorageService.get("last-notifications-seek")))
      : null;
  },

  startNotificationsLoading(state: NotificationsStoreI) {
    state.notificationsLoading = true;
  },

  endNotificationsLoading(state: NotificationsStoreI) {
    state.notificationsLoading = false;
  },

  startNotificationsUnreadLoading(state: NotificationsStoreI) {
    state.notificationsUnreadLoading = true;
  },

  endNotificationsUnreadLoading(state: NotificationsStoreI) {
    state.notificationsUnreadLoading = false;
  },

  setNotifications(state: NotificationsStoreI, notifications) {
    state.notifications = notifications;
    state.notifications.sort((a, b) => b.updated.getTime() - a.updated.getTime());
  },

  removeNotification(state: NotificationsStoreI, notificationId) {
    const index = state.notifications.findIndex((x) => x.id == notificationId);
    if (index >= 0) {
      state.notifications.splice(index, 1);
    }
  },

  addNotification(state: NotificationsStoreI, notification) {
    const index = state.notifications.findIndex((x) => x.id == notification.id);
    if (index >= 0) {
      Vue.set(state.notifications, index, notification);
    } else {
      state.notifications.unshift(notification);
    }
    state.notifications.sort((a, b) => b.updated.getTime() - a.updated.getTime());
  },

  dismissPopupNotification(state: NotificationsStoreI, notificationId) {
    const index = state.popupNotifications.indexOf(notificationId);
    if (index >= 0) {
      state.popupNotifications.splice(index, 1);
    }
  },

  addPopupNotification(state: NotificationsStoreI, notification) {
    if (!state.enabledPopupNotifications) return;
    if (notification.notificationType == "ANNOUNCEMENT" || notification.notificationType == "NEW_MISSED_CALL") return; // Dont add announcement to popups
    const index = state.popupNotifications.indexOf(notification.id);
    if (index >= 0) {
      state.popupNotifications.splice(index, 1);
    }
    state.popupNotifications.unshift(notification.id);
  },

  removeUnreadNotification(state: NotificationsStoreI, notificationId) {
    state.unreadNotifications = state.unreadNotifications.filter((x) => x != notificationId);
  },

  addUnreadNotification(state: NotificationsStoreI, notification) {
    if (!state.unreadNotifications.includes(notification.id) && state.notifications.some((x) => x.id == notification.id)) {
      state.unreadNotifications.push(notification.id);
    }
  },

  setUnreadNotifications(state: NotificationsStoreI, unreadNotifications) {
    state.unreadNotifications = unreadNotifications;
  },

  setLastNotificationSeek(state: NotificationsStoreI) {
    const date = new Date();
    StorageService.save("last-notifications-seek", date.getTime().toString());
    state.lastNotificationSeek = date;
  },

  togglePopupNotifications(state: NotificationsStoreI, value) {
    state.popupNotifications = [];
    if (value) {
      state.enabledPopupNotifications = true;
      StorageService.remove("notifications-popup-disabled");
    } else {
      state.enabledPopupNotifications = false;
      StorageService.save("notifications-popup-disabled", "true");
    }
  },
};
const actions = {
  async shouldReadNotification(context, notification) {
    if (!context.state.unreadNotifications.includes(notification.id)) return;
    const route = Router.currentRoute;
    let read = false;
    if (notification.notificationType == "NEW_MESSAGE") {
      read = route.name == "app.conference.meeting" && route.params.meetingId == notification.objectId;
    } else if (notification.notificationType == "STAGE_START") {
      read = route.name == "app.conference.stage" && route.params.stageId == notification.objectId;
    } else if (notification.notificationType == "NEW_POLL") {
      const obj = CommonService.notificationActionTypes()[notification.data.objectActionType];
      read = route.name == obj.name && route.params[obj.paramName] == notification.data.objectId;
    } else if (notification.notificationType == "ANNOUNCEMENT") {
      const announcement = new Announcement(notification.data);
      if (announcement.cta_type == AnnouncementTypes.NO_ACTION || announcement.cta_type == AnnouncementTypes.CUSTOM) return;
      else {
        const obj = CommonService.notificationActionTypes()[notification.data.cta_type];
        if (obj.paramName) {
          read = route.name == obj.name && route.params[obj.paramName] == announcement.objectId();
        } else {
          read = route.name == obj.name;
        }
      }
    } else if (notification.notificationType == "SESSION_START") {
      read = route.name == "app.conference.session" && route.params.sessionId == notification.objectId;
    } else if (notification.notificationType == "NEW_CALL") {
      read = false;
    }
    if (read) Store.dispatch("notifications/readNotification", { conferenceId: route.params.conferenceId, notification });
  },

  async handleNotificationUpdated({ commit }, data) {
    const notification = new NotificationModel(data);
    commit("addNotification", notification);
    commit("addUnreadNotification", notification);
    commit("addPopupNotification", notification);

    Store.dispatch("notifications/shouldReadNotification", notification);
  },

  handleNotificationDeleted({ commit }, data) {
    commit("removeNotification", data);
    commit("dismissPopupNotification", data);
    commit("removeUnreadNotification", data);
  },

  async fetchConferenceUnreadNotifications({ commit, state }, { conferenceId }) {
    if (state.notificationsLoading != null) return;

    commit("startNotificationsUnreadLoading");
    const response = await NotificationsService.getConferenceNotificationsUnread(conferenceId, null);
    commit(
      "setNotifications",
      response.notifications.map((x) => new NotificationModel(x.notification))
    );
    commit(
      "setUnreadNotifications",
      response.notifications.map((x) => x.notification.id)
    );
    commit("endNotificationsUnreadLoading");
  },

  async fetchConferenceNotifications({ commit }, { conferenceId }) {
    commit("startNotificationsLoading");
    const response = await NotificationsService.getConferenceNotifications(conferenceId, null);
    commit(
      "setNotifications",
      response.map((x) => new NotificationModel(x.notification))
    );
    commit(
      "setUnreadNotifications",
      response.filter((x) => !x.is_read).map((x) => x.notification.id)
    );
    commit("endNotificationsLoading");
    return response;
  },

  async fetchConferenceNotificationsNextPage({ commit, state }, { conferenceId }) {
    if (state.notificationsNextPage == null) return;
    commit("startNotificationsLoading");
    const filters = {
      page: state.notificationsNextPage,
    };
    try {
      const response = await NotificationsService.getConferenceNotifications(conferenceId, filters);
      response.forEach((n) => commit("addNotification", new NotificationModel(n.notification)));
      response.filter((n) => !n.is_read).forEach((n) => commit("addUnreadNotification", new NotificationModel(n.notification)));
      state.notificationsNextPage++;
      return response;
    } catch (error) {
      state.notificationsNextPage = null;
      console.error(error);
    } finally {
      commit("endNotificationsLoading");
    }
  },

  async updateLastNotificationsSeek({ commit }) {
    commit("setLastNotificationSeek");
  },

  async readNotification({ commit, state }, { conferenceId, notification }) {
    if (!state.unreadNotifications.includes(notification.id)) return;
    commit("removeUnreadNotification", notification.id);
    Store.dispatch("meetings/handleNotificationRead", notification);

    if (["NEW_CALL", "NEW_CALL_ENDED"].includes(notification.notificationType)) return;
    NotificationsService.readNotification(conferenceId, notification.id);
  },

  async readAllNotifications({ commit }, { conferenceId }) {
    NotificationsService.readNotificationAll(conferenceId);
    commit("setUnreadNotifications", []);
    Store.dispatch("meetings/handleAllNotificationsRead");
  },

  async tryToReadNotification({ state }, { notificationType, notificationObjectId }) {
    const notification = state.notifications.find((x) => x.notificationType == notificationType && x.objectId == notificationObjectId);
    if (notification) {
      Store.dispatch("notifications/readNotification", {
        conferenceId: Router.currentRoute.params.conferenceId,
        notification: notification,
      });
    }
  },

  togglePopupNotifications({ commit }, value) {
    commit("togglePopupNotifications", value);
  },
};

const notifications = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default notifications;
