import Vue from "vue";
import { SocketEvents } from "@/types/events.types";
import TokenService from "@/services/token.service";
// import MessageModel from "@/models/message.model";
import Store from "@/store";
import Router from "@/router";

const SocketState = {
  0: "CONNECTING",
  1: "OPEN",
  2: "CLOSING",
  3: "CLOSED",
  4: "RETRYING",
};

const SocketStateNumber = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
  RETRYING: 4,
};

const SocketService = {
  debug: process.env.VUE_APP_DEBUG == "true",
  socket: null,
  status: "CLOSED",
  connected: false,
  reconnectTimer: 0,
  reconnectTimeout: 5000,

  heartbeatTimer: 0,
  heartbeatTimeout: 60000,

  active: false,

  setActive(active) {
    this.active = active;
    if (active) {
      this.connect();
    } else {
      this.disconnect();
    }
  },

  async _getAccessToken() {
    TokenService.getAccessToken();

    // Check if token expired
    if (TokenService.accessToken && TokenService.accessTokenExpired) {
      // Refresh token
      return TokenService.refreshAccessToken().then(() => {
        return TokenService.accessToken;
      });
    } else {
      // Make request
      return TokenService.accessToken;
    }
  },

  // ------------------------------------ Init ------------------------------------
  // async connect (connectionCallback: () => any) {
  async connect(connectionCallback) {
    return new Promise((resolve, reject) => {
      this.disconnect();

      this._getAccessToken().then((accessToken) => {
        const path =
          process.env.VUE_APP_WEBSOCKET + "conference/?token=" + accessToken + "&conference_id=" + Router.currentRoute.params.conferenceId;
        this.socket = new WebSocket(path);

        // On message
        this.socket.onmessage = (message) => {
          // console.info("[SOCKET] Message:", message);
          Vue.nextTick(() => {
            this.handleMessage(message);
          });
        };

        // On open
        this.socket.onopen = () => {
          if (this.debug) console.info("[SOCKET] Open!");
          if (connectionCallback) connectionCallback();
          this._updateState();
          Vue.prototype.$events.$emit(SocketEvents.CONNECTED);
          this._startHeartbeat();
          resolve();
        };

        // On error
        this.socket.onerror = (error) => {
          if (this.debug) console.warn("[SOCKET] Error:", error);
          Vue.prototype.$events.$emit(SocketEvents.DISCONNECTED, error);
          this._updateState();
          this._stopHeartbeat();
          reject(error);
        };

        // On close
        this.socket.onclose = () => {
          if (this.debug) console.warn("[SOCKET] Closed!");
          Vue.prototype.$events.$emit(SocketEvents.DISCONNECTED);
          this._updateState();
          this._stopHeartbeat();
        };

        // Call onopen directly if socket is already open
        if (WebSocket && this.socket.readyState == WebSocket.OPEN) {
          this.socket.onopen(new Event("CONNECTED", undefined));
        }

        // Start reconnect pinger
        clearInterval(this.reconnectTimer);
        this.reconnectTimer = setInterval(() => {
          if (this.socket.readyState == WebSocket.CLOSED) {
            this._updateState(SocketStateNumber.RETRYING);
            this.connect(connectionCallback);
          }
        }, this.reconnectTimeout);
      });
    });
  },

  // ------------------------------------ Disconnect ------------------------------------
  disconnect() {
    if (this.socket) {
      if (this.debug) console.warn("[SOCKET] Disconnect");
      clearInterval(this.reconnectTimer);
      this.socket.close();
      this.socket = null;
      this._stopHeartbeat();
    }
  },

  // ------------------------------------ Handle message ------------------------------------
  // handleMessage (message: any) {
  handleMessage(message) {
    const data = JSON.parse(message.data);
    if (this.debug) console.log("[SOCKET] Message:", data);
    Vue.prototype.$events.$emit(SocketEvents.MESSAGE, data);

    // If the message is a conference event make sure its the correct one
    if (data.conference_id && Router.currentRoute.params.conferenceId && data.conference_id != Router.currentRoute.params.conferenceId) {
      return;
    }

    switch (data.type) {
      case "ATTENDEES_ACTIVE":
        Store.dispatch(
          "conference/updateActiveAttendees",
          data.data.attendees.map((attendee) => parseInt(attendee))
        );
        break;
      case "CHAT_MESSAGE":
        if (this.debug) console.log("[SOCKET] New chat message:", data.data);
        Store.dispatch("meetings/handleNewMessage", data.data);
        break;
      case "CHAT_MESSAGE_DELETED":
        if (this.debug) console.log("[SOCKET] Chat message deleted:", data.data);
        break;
      case "CHAT_MESSAGE_UPDATED":
        if (this.debug) console.log("[SOCKET] Chat message updated:", data.data);
        break;
      case "CHAT_MESSAGE_LIKED":
        if (this.debug) console.log("[SOCKET] Chat message liked:", data.data);
        break;
      case "CHAT_ATTACHMENT_UPLOADED":
        if (this.debug) console.log("[SOCKET] New chat attachment:", data.data);
        Store.dispatch("meetings/handleNewChatAttachment", data.data);
        break;
      case "CHAT_BANNED":
        if (this.debug) console.log("[SOCKET] Chat banned:", data.data);
        // Store.dispatch("chat/handleChatBanned", data.data);
        break;
      case "CHAT_VIDEO_STARTED":
        if (this.debug) console.log("[SOCKET] Video chat started:", data.data);
        Store.dispatch("meetings/handleVideoStarted", data.data);
        break;
      case "CHAT_VIDEO_ENDED":
        if (this.debug) console.log("[SOCKET] Video chat ended:", data.data);
        Store.dispatch("meetings/handleVideoEnded", data.data);
        break;
      case "STAGE_VIEW_COUNTER":
        if (this.debug) console.log("[SOCKET] Stage view counter change:", data.data);
        Store.dispatch("conference/updateStageViewCounter", data.data);
        break;
      case "STREAM_STARTED":
        if (this.debug) console.log("[SOCKET] Stage stream started:", data.data);
        Store.dispatch("conference/handleStreamChange", data.data);
        break;
      case "STREAM_ENDED":
        if (this.debug) console.log("[SOCKET] Stage stream ended:", data.data);
        Store.dispatch("conference/handleStreamChange", data.data);
        break;
      case "SIGNIN_ON_ANOTHER_DEVICE":
        if (this.debug) console.log("[SOCKET] Sign in on another device:", data.data);
        Store.dispatch("general/lock", data.data);
        break;
      case "POLL_PUBLISHED":
        if (this.debug) console.log("[SOCKET] Poll published:", data.data);
        Store.dispatch("polls/handlePollPublished", data.data);
        break;
      case "POLL_UNPUBLISHED":
        if (this.debug) console.log("[SOCKET] Poll published:", data.data);
        Store.dispatch("polls/handlePollUnpublished", data.data);
        break;
      case "POLL_CLOSED":
        if (this.debug) console.log("[SOCKET] Poll published:", data.data);
        Store.dispatch("polls/handlePollClosed", data.data);
        break;
      case "POLL_OPENED":
        if (this.debug) console.log("[SOCKET] Poll published:", data.data);
        Store.dispatch("polls/handlePollOpened", data.data);
        break;
      case "POLL_VOTED":
        if (this.debug) console.log("[SOCKET] Poll voted:", data.data);
        Store.dispatch("polls/handlePollChoiceVoted", data.data);
        break;
      case "QA_QUESTION_CREATED":
        if (this.debug) console.log("[SOCKET] Qa question created:", data.data);
        break;
      case "QA_QUESTION_ANSWERED":
        if (this.debug) console.log("[SOCKET] Qa question answered:", data.data);
        break;
      case "QA_QUESTION_DISMISSED":
        if (this.debug) console.log("[SOCKET] Qa question dismissed:", data.data);
        break;
      case "QA_QUESTION_REOPENED":
        if (this.debug) console.log("[SOCKET] Qa question reopened:", data.data);
        break;
      case "QA_QUESTION_UPDATED_VISIBILITY":
        if (this.debug) console.log("[SOCKET] Qa question visibility changed:", data.data);
        break;
      case "QA_QUESTION_DELETED":
        if (this.debug) console.log("[SOCKET] Qa question deleted:", data.data);
        break;
      case "ANNOUNCEMENT":
        if (this.debug) console.log("[SOCKET] New announcement:", data.data);
        Store.dispatch("conference/handleNewAnnouncement", data.data);
        break;
      case "NOTIFICATION_CREATED":
        Store.dispatch("notifications/handleNotificationUpdated", data.data);
        break;
      case "NOTIFICATION_UPDATED":
        Store.dispatch("notifications/handleNotificationUpdated", data.data);
        break;
      case "NOTIFICATION_DELETED":
        Store.dispatch("notifications/handleNotificationDeleted", data.data);
        break;
      case "NEW_MEETING":
        if (this.debug) console.log("[SOCKET] New meeting:", data.data);
        Store.dispatch("meetings/handleNewMeeting", data.data);
        break;
      case "SESSION_VIDEO_STARTED":
        if (this.debug) console.log("[SOCKET] Session video started:", data.data);
        Store.dispatch("conference/handleSessionVideoChange", data.data);
        break;
      case "SESSION_VIDEO_ENDED":
        if (this.debug) console.log("[SOCKET] Session video ended:", data.data);
        Store.dispatch("conference/handleSessionVideoChange", data.data);
        break;
      case "SESSION_VIDEO_CLOSED":
        if (this.debug) console.log("[SOCKET] Session video closed:", data.data);
        Store.dispatch("conference/handleSessionVideoClosed", data.data);
        break;
      case "ZOOM_AUTHORIZED":
        if (this.debug) console.log("[SOCKET] Zoom authorized:", data.data);
        break;
      case "CONFERENCE_UPDATED":
        if (this.debug) console.log("[SOCKET] Conference updated:", data.data);
        Store.dispatch("conference/handleConferenceUpdated", data.data);
        break;
      case "STAGE_CREATED":
        if (this.debug) console.log("[SOCKET] Stage created:", data.data);
        Store.dispatch("conference/handleStageCreated", data);
        break;
      case "STAGE_UPDATED":
        if (this.debug) console.log("[SOCKET] Stage updated:", data.data);
        Store.dispatch("conference/handleStageUpdated", data);
        break;
      case "STAGE_REMOVED":
        if (this.debug) console.log("[SOCKET] Stage deleted:", data.data);
        Store.dispatch("conference/handleStageDeleted", data);
        break;
      case "SESSION_CREATED":
        if (this.debug) console.log("[SOCKET] Session created:", data.data);
        Store.dispatch("conference/handleSessionCreated", data);
        break;
      case "SESSION_UPDATED":
        if (this.debug) console.log("[SOCKET] Session updated:", data.data);
        Store.dispatch("conference/handleSessionUpdated", data);
        break;
      case "SESSION_REMOVED":
        if (this.debug) console.log("[SOCKET] Session deleted:", data.data);
        Store.dispatch("conference/handleSessionDeleted", data);
        break;
      case "SHOWROOM_CREATED":
        if (this.debug) console.log("[SOCKET] Showroom created:", data.data);
        Store.dispatch("conference/handleShowroomCreated", data);
        break;
      case "SHOWROOM_UPDATED":
        if (this.debug) console.log("[SOCKET] Showroom updated:", data.data);
        Store.dispatch("conference/handleShowroomUpdated", data);
        break;
      case "SHOWROOM_REMOVED":
        if (this.debug) console.log("[SOCKET] Showroom deleted:", data.data);
        Store.dispatch("conference/handleShowroomDeleted", data);
        break;
      case "ATTENDEE_UPDATED":
        if (this.debug) console.log("[SOCKET] Attendee updated:", data.data);
        Store.dispatch("conference/handleAttendeeUpdated", data.data);
        break;
      case "ATTENDEE_REMOVED":
        if (this.debug) console.log("[SOCKET] Attendee deleted:", data.data);
        Store.dispatch("conference/handleAttendeeRemoved", data.data);
        break;
      default:
        if (this.debug) console.warn("Unkown socket message type:", data);
    }
  },

  // ------------------------------------ Send ------------------------------------
  // async send (payload: Record<string, any>): Promise<any> {
  async send(payload) {
    if (this.socket && WebSocket.OPEN && !WebSocket.CONNECTING) {
      if (this.debug) console.info("[SOCKET] Send:", payload);
      await this.socket.send(JSON.stringify(payload));
      return payload;
    } else if (WebSocket.CONNECTING) {
      // Still connecting
      console.warn("[SOCKET] Socket still connection! Failed to send:", payload);
      return new Error("Socket still connection! Failed to send");
    } else {
      // Not connected
      console.warn("[SOCKET] Socket not connected! Failed to send:", payload);
      return new Error("Socket not connected! Failed to send");
    }
  },

  // ------------------------------------ Update state in store ------------------------------------
  _updateState(state) {
    this.status =
      state != undefined ? SocketState[state] : this.socket ? SocketState[this.socket.readyState] : SocketState[SocketStateNumber.CLOSED];
    if (this.debug) console.log("[SOCKET] New state:", this.status);
    Store.commit("general/setSocketStatus", this.status);
    this.connected = this.status == SocketState[1];
  },

  // ------------------------------------ Update online status ------------------------------------
  _updateOnlineStatus(e) {
    if (e.type == "offline") {
      SocketService.disconnect();
    } else if (e.type == "online" && this.active) {
      SocketService.connect();
    }
  },

  // ------------------------------------ Heartbeat ------------------------------------
  _startHeartbeat() {
    clearInterval(this.heartbeatTimer);
    this.send({ type: "HEARTBEAT" });
    this.heartbeatTimer = setInterval(() => {
      this.send({ type: "HEARTBEAT" });
    }, this.heartbeatTimeout);
  },

  _stopHeartbeat() {
    clearInterval(this.heartbeatTimer);
  },
};

window.addEventListener("online", SocketService._updateOnlineStatus);
window.addEventListener("offline", SocketService._updateOnlineStatus);

export default SocketService;
