import Vue from "vue";
import AttendeeService from "@/services/api/attendee.service";
import ManagementService from "@/services/api/management.service";
import ConferenceModel, { ConferenceSettingsModel } from "@/models/conference.model";
import AttendeeGroupModel from "@/models/attendee-group.model";
import AttendeeModel from "@/models/attendee.model";
import StageModel from "@/models/stage.model";
import Announcement from "@/models/announcement.model";
import Store from "@/store";
import EmailTemplatesService from "@/services/api/email-template.service";

// ---------------------------------------------------------------- State ----------------------------------------------------------------
const state = {
  conferences: Array<ConferenceModel>(),
  conference: null,
  stages: Array<StageModel>(),
  stage: null,
  loadingAttendees: false,
  attendees: Array<AttendeeModel>(),
  groups: Array<AttendeeGroupModel>(),
  groupOptions: {},
  showroomGroups: [],
  showrooms: [],
  sessions: [],
  announcements: [],
  stageViews: null,
  attendeeStats: null,
  polls: [],
  users: [],
  conferenceFiles: [],
  emailTemplate: null,
};

// ---------------------------------------------------------------- Getters ----------------------------------------------------------------
const getters = {};

// ---------------------------------------------------------------- Mutations --------------------------------------------------------------
const mutations = {
  setConferences(state, conferences: Array<ConferenceModel>) {
    state.conferences = conferences;
  },

  setConference(state, conference: ConferenceModel) {
    state.conference = conference;
    if (state.conference) {
      Store.dispatch("general/setConferenceDarkMode", conference.dark_mode);
    }
  },

  setConferenceSettings(state, settings) {
    if (state.conference) {
      state.conference.settings = new ConferenceSettingsModel(settings);
    }
  },

  setConferenceStages(state, stages: Array<StageModel>) {
    state.stages = stages;
  },

  setConferenceStage(state, stage: StageModel) {
    state.stage = stage;
  },

  updateConferenceStage(state, stage: StageModel) {
    const stageIndex = state.stages.findIndex((u: StageModel) => u.id == stage.id);
    if (stageIndex >= 0) {
      // Update
      Vue.set(state.stages, stageIndex, stage);
    } else {
      // Add
      state.stages.unshift(stage);
    }
  },

  updateConferenceStageStreamLatency(state, { stageId, latency }) {
    if (state.stage && state.stage.id == stageId) {
      Vue.set(state.stage.stream, "latency_mode", latency);
    }
  },

  deleteConferenceStage(state, stageId: number) {
    state.stages = state.stages.filter((u: StageModel) => u.id != stageId);
  },

  setConferenceShowrooms(state, showrooms: Array<any>) {
    state.showrooms = showrooms;
  },

  setConferenceShowroom(state, showroom: any) {
    state.showroom = showroom;
  },

  setConferenceShowroomGroups(state, groups) {
    state.showroomGroups = groups;
  },

  updateConferenceShowroomGroup(state, showroomGroup) {
    const showroomGroupIndex = state.showroomGroups.findIndex((u) => u.id == showroomGroup.id);
    if (showroomGroupIndex >= 0) {
      // Update
      Vue.set(state.showroomGroups, showroomGroupIndex, showroomGroup);
    } else {
      // Add
      state.showroomGroups.unshift(showroomGroup);
    }
  },

  deleteConferenceShowroomGroup(state, showroomGroupId) {
    state.showroomGroups = state.showroomGroups.filter((u) => u.id != showroomGroupId);
  },

  updateConferenceShowroom(state, showroom: any) {
    const showroomIndex = state.showrooms.findIndex((u) => u.id == showroom.id);
    if (showroomIndex >= 0) {
      // Update
      Vue.set(state.showrooms, showroomIndex, showroom);
    } else {
      // Add
      state.showrooms.unshift(showroom);
    }
  },

  deleteConferenceShowroom(state, showroomId: number) {
    state.showrooms = state.showrooms.filter((s) => s.id != showroomId);
  },

  setConferenceSessions(state, sessions: Array<any>) {
    state.sessions = sessions;
  },

  setConferenceSession(state, session: any) {
    state.session = session;
  },

  updateConferenceSession(state, session: any) {
    const sessionIndex = state.sessions.findIndex((u) => u.id == session.id);
    if (sessionIndex >= 0) {
      // Update
      Vue.set(state.sessions, sessionIndex, session);
    } else {
      // Add
      state.sessions.unshift(session);
    }
  },

  deleteConferenceSession(state, sessionId: number) {
    state.sessions = state.sessions.filter((s) => s.id != sessionId);
  },

  setAttendeeGroups(state, groups: Array<AttendeeGroupModel>) {
    state.groups = groups;
  },

  setAttendeeGroup(state, group: AttendeeGroupModel) {
    const index = state.groups.findIndex((u: AttendeeGroupModel) => u.id == group.id);
    if (index >= 0) {
      // Update
      Vue.set(state.groups, index, group);
    } else {
      // Add
      state.groups.unshift(group);
    }
  },

  setAttendeeGroupOptions(state, options: any) {
    state.groupOptions = options;
  },

  deleteAttendeeGroup(state, groupId: number) {
    state.groups = state.groups.filter((g) => g.id != groupId);
  },

  setAttendees(state, attendees: Array<AttendeeModel>) {
    state.attendees = attendees;
  },

  setAttendee(state, attendee: AttendeeModel) {
    const index = state.attendees.findIndex((u: AttendeeModel) => u.id == attendee.id);
    if (index >= 0) {
      // Update
      Vue.set(state.attendees, index, attendee);
    } else {
      // Add
      state.attendees.unshift(attendee);
    }

    const currAttendee = Store.getters["conference/currentAttendee"];
    if (currAttendee && currAttendee.id == attendee.id) {
      Store.commit("conference/setConferenceCurrentAttendee", attendee);
    }
  },

  setConferenceFiles(state, files) {
    state.conferenceFiles = files;
  },

  setConferenceFile(state, file) {
    const itemIndex = state.conferenceFiles.findIndex((p) => file.id == p.id);
    if (itemIndex >= 0) {
      Vue.set(state.conferenceFiles, itemIndex, file);
    } else {
      state.conferenceFiles.unshift(file);
    }
  },

  setConferenceUsers(state, users) {
    state.users = users;
  },

  setConferenceUser(state, user) {
    const itemIndex = state.users.findIndex((p) => user.id == p.id);
    if (itemIndex >= 0) {
      Vue.set(state.users, itemIndex, user);
    } else {
      state.users.unshift(user);
    }
  },

  setAnnouncements(state, announcements: any[]) {
    state.announcements = announcements;
  },

  setAnnouncement(state, announcement: any) {
    const index = state.announcements.findIndex((u: Announcement) => u.id == announcement.id);
    if (index >= 0) {
      // Update
      Vue.set(state.announcements, index, announcement);
    } else {
      // Add
      state.announcements.unshift(announcement);
    }
  },

  deleteAnnouncement(state, announcementId: number) {
    state.announcements = state.announcements.filter((a) => a.id != announcementId);
  },

  setStageViews(state, data: any) {
    state.stageViews = data;
  },

  setAttendeeStats(state, data: any) {
    state.attendeeStats = data;
  },

  setEmailTemplate(state, template) {
    state.emailTemplate = template;
  },

  cleanConference(state) {
    state.stage = null;
    state.stages = [];
    state.groups = [];

    state.loadingAttendees = false;
    state.attendees = [];

    state.announcements = [];
    state.users = [];
    state.conferenceFiles = [];
    state.stageViews = null;

    state.emailTemplate = null;
  },

  cleanStore(state) {
    state.conferences = [];
    state.conference = null;
    state.stage = null;
    state.stages = [];
    state.groups = [];

    state.loadingAttendees = false;
    state.attendees = [];

    state.announcements = [];
    state.users = [];
    state.conferenceFiles = [];
    state.stageViews = null;

    state.emailTemplate = null;
  },
};

// ---------------------------------------------------------------- Actions ----------------------------------------------------------------
const actions = {
  async fetchConferences(context, filters) {
    try {
      const response = await ManagementService.getConferences(filters);
      const conferences: Array<ConferenceModel> = [];
      for (let i = 0; i < response.length; i++) {
        conferences.push(new ConferenceModel(response[i]));
      }
      context.commit("setConferences", conferences);
      return conferences;
    } catch (error) {
      throw error;
    }
  },

  async fetchConference(context, conferenceId: number) {
    if (context.state.conference && context.state.conference.id == conferenceId) {
      return context.state.conference;
    } else {
      try {
        const response = await ManagementService.getConference(conferenceId);
        const conference = new ConferenceModel(response);
        context.commit("setConference", conference);
        // await context.dispatch("fetchConferenceStages", { conferenceId }); // this was needed on Conference Analytics & Stage Dashboard page reload
        // await context.dispatch("fetchAttendees", { conferenceId }); // TODO later implement filters for ManagmenentAttendeesView
        // await context.dispatch("fetchAttendeeGroups", { conferenceId });
        // if (conference.has_showrooms) await context.dispatch("fetchConferenceShowrooms", { conferenceId });
        // await context.dispatch("fetchConferenceSessions", { conferenceId });
        return conference;
      } catch (error) {
        throw error;
      }
    }
  },

  async updateConference(context, form: any) {
    try {
      const response = await ManagementService.updateConference(form);
      const conference = new ConferenceModel(response);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference '%{ conference_name }' was updated."), {
          conference_name: form.name,
        }),
        "success"
      );
      context.commit("setConference", conference);

      // Set conference to null so it has to refresh the conference data when leaving managament view
      Store.commit("conference/setConference", null);

      return conference;
    } catch (error) {
      const errorMessage = error.message.non_field_errors ? error.message.non_field_errors.join(", ") : error.message;
      Vue.prototype.$events.$emit("nice-notification", "ERROR", errorMessage, Vue.prototype.$gettext("Error") + " " + error.errorCode);
      throw error;
    }
  },

  async updateConferenceSettings(context, { conferenceId, settings }) {
    const response = await ManagementService.updateConferenceSettings(conferenceId, settings);
    context.commit("setConferenceSettings", response);
    return response;
  },

  // ----------------------------------- Stages -----------------------------------
  async fetchConferenceStages(context, { conferenceId, filters }) {
    try {
      const response = await ManagementService.getStages(conferenceId, filters);
      const stages: Array<StageModel> = [];
      for (let i = 0; i < response.length; i++) {
        stages.push(new StageModel(response[i]));
      }
      stages["_metadata"] = response._metadata;
      context.commit("setConferenceStages", stages);
      return stages;
    } catch (error) {
      throw error;
    }
  },

  async fetchConferenceStage(context, { conferenceId, stageId }) {
    try {
      const response = await ManagementService.getStage(conferenceId, stageId);
      const stage = new StageModel(response);
      context.commit("setConferenceStage", stage);
      return stage;
    } catch (error) {
      throw error;
    }
  },

  async createConferenceStage({ commit }, { conferenceId, data }) {
    try {
      const response = await ManagementService.createStage(conferenceId, data);
      const stage = new StageModel(response);
      commit("updateConferenceStage", stage);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference stage '%{ stage_name }' was created."), {
          stage_name: stage.name,
        }),
        "success"
      );
      return stage;
    } catch (error) {
      const errorMessage = error.message.non_field_errors ? error.message.non_field_errors.join(", ") : error.message;
      Vue.prototype.$events.$emit("nice-notification", "ERROR", errorMessage, Vue.prototype.$gettext("Error") + " " + error.errorCode);
      throw error;
    }
  },

  async updateConferenceStage({ commit }, { conferenceId, data }) {
    try {
      const response = await ManagementService.updateStage(conferenceId, data);
      const stage = new StageModel(response);
      commit("updateConferenceStage", stage);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference stage '%{ stage_name }' was updated."), {
          stage_name: stage.name,
        }),
        "success"
      );
      return stage;
    } catch (error) {
      const errorMessage = error.message.non_field_errors ? error.message.non_field_errors.join(", ") : error.message;
      Vue.prototype.$events.$emit("nice-notification", "ERROR", errorMessage, Vue.prototype.$gettext("Error") + " " + error.errorCode);
      throw error;
    }
  },

  async deleteConferenceStage({ commit }, { conferenceId, stage }) {
    try {
      const response = await ManagementService.deleteStage(conferenceId, stage.id);
      commit("deleteConferenceStage", stage.id);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference stage '%{ showroom_name }' was deleted."), {
          showroom_name: stage.name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async updateConferenceStageStreamLatency({ commit }, { conferenceId, stageId, latencyMode }) {
    try {
      const response = await ManagementService.updateStageStreamLatency(conferenceId, stageId, latencyMode);
      commit("updateConferenceStageStreamLatency", { stageId, latency: response.latency_mode });
      Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("Conference stage latency was updated."), "success");
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.errorCode);
      // throw error;
    }
  },

  async updateConferenceStageOrder(context, { conferenceId, stages }) {
    stages.forEach((stage) => {
      context.commit("updateConferenceStage", stage);
    });
    try {
      const response = await ManagementService.updateStagesOrder(conferenceId, stages);
      return response;
    } catch (error) {
      throw error;
    }
  },

  // ----------------------------------- Showrooms -----------------------------------
  async fetchConferenceShowrooms(context, { conferenceId, filters }) {
    try {
      const response = await ManagementService.getShowrooms(conferenceId, filters);
      context.commit("setConferenceShowrooms", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchConferenceShowroom(context, { conferenceId, showroomId }) {
    try {
      const response = await ManagementService.getShowroom(conferenceId, showroomId);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async createConferenceShowroom({ commit }, { conferenceId, data }) {
    try {
      const response = await ManagementService.createShowroom(conferenceId, data);
      commit("updateConferenceShowroom", response);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference showroom '%{ showroom_name }' was created."), {
          showroom_name: response.name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async updateConferenceShowroom({ commit }, { conferenceId, data }) {
    try {
      const response = await ManagementService.updateShowroom(conferenceId, data);
      commit("updateConferenceShowroom", response);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference showroom '%{ showroom_name }' was updated."), {
          showroom_name: response.name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async deleteConferenceShowroom({ commit }, { conferenceId, showroom }) {
    try {
      const response = await ManagementService.deleteShowroom(conferenceId, showroom.id);
      commit("deleteConferenceShowroom", showroom.id);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference showroom '%{ showroom_name }' was deleted."), {
          showroom_name: showroom.name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async fetchConferenceShowroomAttachments(context, { conferenceId, showroomId }) {
    try {
      const response = await ManagementService.getShowroomAttachments(conferenceId, showroomId);
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  // showroom groups

  async fetchConferenceShowroomGroups(context, { conferenceId, filters }) {
    try {
      const response = await ManagementService.getShowroomGroups(conferenceId, filters);
      context.commit("setConferenceShowroomGroups", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async createConferenceShowroomGroup(context, { conferenceId, data }) {
    try {
      const response = await ManagementService.createShowroomGroup(conferenceId, data);
      context.commit("updateConferenceShowroomGroup", response);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference showroom group '%{ showroom_group_name }' was created."), {
          showroom_group_name: response.name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  async updateConferenceShowroomGroup(context, { conferenceId, data }) {
    try {
      const response = await ManagementService.updateShowroomGroup(conferenceId, data);
      context.commit("updateConferenceShowroomGroup", response);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference showroom group '%{ showroom_group_name }' was updated."), {
          showroom_group_name: response.name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  async deleteConferenceShowroomGroup({ commit }, { conferenceId, showroomGroup }) {
    try {
      const response = await ManagementService.deleteShowroomGroup(conferenceId, showroomGroup.id);
      commit("deleteConferenceShowroomGroup", showroomGroup.id);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference showroom group '%{ showroom_group_name }' was deleted."), {
          showroom_group_name: showroomGroup.name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  async updateConferenceShowroomGroupOrder(context, { conferenceId, orderGroups }) {
    context.commit("setConferenceShowroomGroups", orderGroups);
    try {
      const response = await ManagementService.updateShowroomGroupOrder(conferenceId, orderGroups);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async updateConferenceShowroomOrder(context, { conferenceId, showrooms }) {
    showrooms.forEach((showroom) => {
      context.commit("updateConferenceShowroom", showroom);
    });
    if (showrooms.length == 0) return;
    try {
      const response = await ManagementService.updateShowroomsOrder(conferenceId, showrooms);
      return response;
    } catch (error) {
      throw error;
    }
  },

  // ----------------------------------- Sessions -----------------------------------
  async fetchConferenceSessions(context, { conferenceId, filters }) {
    try {
      const response = await ManagementService.getSessions(conferenceId, filters);
      context.commit("setConferenceSessions", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchConferenceSession(context, { conferenceId, sessionId }) {
    try {
      const response = await ManagementService.getSession(conferenceId, sessionId);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async createConferenceSession({ commit }, { conferenceId, data }) {
    try {
      const response = await ManagementService.createSession(conferenceId, data);
      commit("updateConferenceSession", response);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference session '%{ session_name }' was created."), {
          session_name: response.name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      const errorMessage = error.message.non_field_errors ? error.message.non_field_errors.join(", ") : error.message;
      Vue.prototype.$events.$emit("nice-notification", "ERROR", errorMessage, Vue.prototype.$gettext("Error") + " " + error.errorCode);
      throw error;
    }
  },

  async updateConferenceSession({ commit }, { conferenceId, data }) {
    try {
      const response = await ManagementService.updateSession(conferenceId, data);
      commit("updateConferenceSession", response);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference session '%{ session_name }' was updated."), {
          session_name: response.name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      const errorMessage = error.message.non_field_errors ? error.message.non_field_errors.join(", ") : error.message;
      Vue.prototype.$events.$emit("nice-notification", "ERROR", errorMessage, Vue.prototype.$gettext("Error") + " " + error.errorCode);
      throw error;
    }
  },

  async deleteConferenceSession({ commit }, { conferenceId, session }) {
    try {
      const response = await ManagementService.deleteSession(conferenceId, session.id);
      commit("deleteConferenceSession", session.id);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference session '%{ session_name }' was deleted."), {
          session_name: session.name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async endConferenceSession({ commit }, { conferenceId, session }) {
    try {
      const response = await ManagementService.endSession(conferenceId, session.id);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference session '%{ session_name }' was closed."), {
          session_name: session.name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async fetchConferenceSessionAttachments(context, { conferenceId, sessionId }) {
    try {
      const response = await ManagementService.getSessionAttachments(conferenceId, sessionId);
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async startConferenceSessionCompositions(context, { conferenceId, session }) {
    try {
      const response = await ManagementService.startSessionCompositions(conferenceId, session.id);
      Vue.prototype.$events.$emit("nice-toast", response.status, "success");
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async statusConferenceSessionCompositions(context, { conferenceId, session }) {
    try {
      const response = await ManagementService.statusSessionCompositions(conferenceId, session.id);
      Vue.prototype.$events.$emit("nice-toast", response.status, "success");
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async downloadConferenceSessionCompositions(context, { conferenceId, session }) {
    try {
      Vue.prototype.$events.$emit("nice-toast", "Copying composition files from Twilio server to S3.", "info", 10000);
      const response = await ManagementService.downloadSessionCompositions(conferenceId, session.id);
      Vue.prototype.$events.$emit("nice-toast", response.status, "success");
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  // TODO
  async deleteConferenceSessionCompositions(context, { conferenceId, session }) {
    try {
      const response = await ManagementService.deleteSessionCompositions(conferenceId, session.id);
      Vue.prototype.$events.$emit("nice-toast", response.status, "success");
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  // TODO
  async deleteConferenceSessionRecordings(context, { conferenceId, session }) {
    try {
      const response = await ManagementService.deleteSessionRecordings(conferenceId, session.id);
      Vue.prototype.$events.$emit("nice-toast", response.status, "success");
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async updateConferenceSessionOrder(context, { conferenceId, sessions }) {
    sessions.forEach((session) => {
      context.commit("updateConferenceSession", session);
    });
    try {
      const response = await ManagementService.updateSessionsOrder(conferenceId, sessions);
      return response;
    } catch (error) {
      throw error;
    }
  },

  // ----------------------------------- Misc -----------------------------------
  handleStreamChange(context: any, data: any) {
    if (context.state.conference && data.conference == context.state.conference.id) {
      const stage: StageModel = context.state.stages.find((t) => data.stage == t.id);
      if (stage) {
        if (data.is_live)
          Vue.prototype.$events.$emit(
            "nice-toast",
            Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Stage '%{ stage_name }' is live!"), { stage_name: stage.name }),
            "success"
          );
        else
          Vue.prototype.$events.$emit(
            "nice-toast",
            Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Stage '%{ stage_name }' ended."), { stage_name: stage.name }),
            "error"
          );
        stage.updateStream(data.is_live, data.link);
        stage.update();
        if (context.state.stage && data.stage == context.state.stage.id) {
          context.state.stage.updateStream(data.is_live, data.link);
          context.state.stage.update();
        }
      }
    }
  },

  updateStageViewCounter(context: any, data: any) {
    const stage = context.state.stages.find((t) => data.stage_id == t.id);
    if (stage) {
      stage.current_viewer_count = data.viewers;
      if (context.state.stage && context.state.stage.id === data.stage_id) {
        context.state.stage.current_viewer_count = data.viewers;
      }
    }
  },

  leaveConference(context) {
    context.commit("setConference", null);
    context.commit("setConferenceStages", null);
    context.commit("setAttendees", null);
    context.commit("setAttendeeGroups", null);
  },

  async fetchAttendees(context, { conferenceId, filters }) {
    state.loadingAttendees = true;
    try {
      const response = await AttendeeService.getAttendees(conferenceId, filters);
      // const attendees: Array<AttendeeModel> = [];
      const attendees: Array<any> = [];
      for (let i = 0; i < response.length; i++) {
        attendees.push(new AttendeeModel(response[i]));
      }
      attendees["_metadata"] = response._metadata;
      context.commit("setAttendees", attendees);
      return attendees;
    } catch (error) {
      throw error;
    } finally {
      state.loadingAttendees = false;
    }
  },

  async fetchAttendee(context, { conferenceId, attendeeId }) {
    try {
      const response = await AttendeeService.getAttendee(conferenceId, attendeeId);
      const attendee = new AttendeeModel(response);
      context.commit("setAttendee", attendee);
      return attendee;
    } catch (error) {
      throw error;
    }
  },

  async createAttendee({ commit }, { conferenceId, data }) {
    try {
      const response = await AttendeeService.createAttendee(conferenceId, data);
      commit("setAttendee", response);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Attendee '%{ first_name }' was created."), {
          first_name: data.first_name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, "Error " + error.code);
      throw error;
    }
  },

  async updateAttendee({ commit }, { conferenceId, data }) {
    try {
      const response = await AttendeeService.updateAttendee(conferenceId, data);
      commit("setAttendee", response);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Attendee '%{ first_name }' was updated."), {
          first_name: data.first_name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async disableAttendeeChat(context: any, { conferenceId, attendeeId }) {
    try {
      const response = await AttendeeService.disableAttendeeChat(conferenceId, attendeeId, true);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchConferenceEmailTemplate(context: any, { conferenceId }) {
    try {
      const response = await EmailTemplatesService.getPrimaryConferenceEmailTemplate(conferenceId);
      context.commit("setEmailTemplate", response);
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  // -------------------- Groups --------------------
  async fetchAttendeeGroups(context, { conferenceId, filters }) {
    try {
      const response = await AttendeeService.getGroups(conferenceId, filters);
      const groups: Array<AttendeeGroupModel> = [];
      for (let i = 0; i < response.length; i++) {
        groups.push(new AttendeeGroupModel(response[i]));
      }
      groups["_metadata"] = response._metadata;
      context.commit("setAttendeeGroups", groups);
      return groups;
    } catch (error) {
      throw error;
    }
  },

  async fetchAttendeeGroupOptions(context, { conferenceId }) {
    try {
      const response = await AttendeeService.getGroupOptions(conferenceId);
      context.commit("setAttendeeGroupOptions", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchAttendeeGroup(context, { conferenceId, groupId }) {
    try {
      const response = await AttendeeService.getGroup(conferenceId, groupId);
      const attendeeGroup = new AttendeeGroupModel(response);
      context.commit("setAttendeeGroup", attendeeGroup);
      return attendeeGroup;
    } catch (error) {
      throw error;
    }
  },

  async createAttendeeGroup({ commit }, { conferenceId, data }) {
    try {
      const response = await AttendeeService.createGroup(conferenceId, data);
      const attendeeGroup = new AttendeeGroupModel(response);
      commit("setAttendeeGroup", attendeeGroup);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Attendee group '%{ group_name }' was created."), {
          group_name: attendeeGroup.name,
        }),
        "success"
      );
      return attendeeGroup;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async updateAttendeeGroup({ commit }, { conferenceId, data }) {
    try {
      const response = await AttendeeService.updateGroup(conferenceId, data);
      const attendeeGroup = new AttendeeGroupModel(response);
      commit("setAttendeeGroup", attendeeGroup);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Attendee group '%{ group_name }' was updated."), {
          group_name: attendeeGroup.name,
        }),
        "success"
      );
      return attendeeGroup;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async deleteAttendeeGroup({ commit }, { conferenceId, group }) {
    try {
      await AttendeeService.deleteGroup(conferenceId, group.id);
      commit("deleteAttendeeGroup", group.id);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Attendee group '%{ group_name }' was deleted."), {
          group_name: group.name,
        }),
        "success"
      );
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async sendInvitationEmails(context: any, { conferenceId, filters }) {
    try {
      const response = await AttendeeService.sendInvitations(conferenceId, filters);
      // Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("Attendees invitation emails were sent."), "success");
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async sendInvitationEmail(context: any, { conferenceId, attendeeId }) {
    try {
      const response = await AttendeeService.sendInvitation(conferenceId, attendeeId);
      Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("Invitation email was sent."), "success");
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async fetchStageViews(context: any, conferenceId: number) {
    try {
      const response = await ManagementService.getStageViews(conferenceId);
      context.commit("setStageViews", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchAttendeeStats(context: any, conferenceId: number) {
    try {
      const response = await ManagementService.getAttendeeStats(conferenceId);
      context.commit("setAttendeeStats", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  // ----------------------------------- Announcements -----------------------------------
  async fetchAnnouncements(context, { conferenceId, filters }) {
    try {
      const response = await ManagementService.getAnnouncements(conferenceId, filters);
      context.commit("setAnnouncements", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchAnnouncement(context, { conferenceId, showroomId }) {
    try {
      const response = await ManagementService.getAnnouncement(conferenceId, showroomId);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async createAnnouncement(context, { conferenceId, data }) {
    try {
      const response = await ManagementService.createAnnouncement(conferenceId, data);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference announcement '%{ title }' was created."), {
          title: data.title,
        }),
        "success"
      );
      context.commit("setAnnouncement", response);
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async updateAnnouncement(context, { conferenceId, data }) {
    try {
      const response = await ManagementService.updateAnnouncement(conferenceId, data.id, data);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference announcement '%{ title }' was updated."), {
          title: data.title,
        }),
        "success"
      );
      context.commit("setAnnouncement", response);
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async deleteAnnouncement(context, { conferenceId, announcement }) {
    try {
      const response = await ManagementService.deleteAnnouncement(conferenceId, announcement.id);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference announcement '%{ title }' was deleted."), {
          title: announcement.title,
        }),
        "success"
      );
      context.commit("deleteAnnouncement", announcement.id);
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  async duplicateAnnouncement(context, { conferenceId, announcement }) {
    try {
      const response = await ManagementService.duplicateAnnouncement(conferenceId, announcement.id);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference announcement '%{ title }' was duplicated."), {
          title: announcement.title,
        }),
        "success"
      );
      context.commit("setAnnouncement", response);
      return response;
    } catch (error) {
      Vue.prototype.$events.$emit("nice-notification", "ERROR", error.message, Vue.prototype.$gettext("Error") + " " + error.code);
      throw error;
    }
  },

  handleAnnouncementPublished(context: any, data: any) {
    const index = state.announcements.findIndex((u: Announcement) => u.id == data.id);
    if (index >= 0) {
      state.announcements[index].published = true;
    }
  },

  // TODO what happens when recordings change
  // async handleSessionRecordingsChange(context, { conferenceId, sessionId }) {
  //   console.warn("handleSessionRecordingsChange", context, conferenceId, sessionId);
  //   try {
  //     const response = await ManagementService.getSessionRecordings(conferenceId, sessionId);
  //     console.warn("ManagementService.getSessionRecordings", response);
  //     return response;
  //   } catch (error) {
  //     throw error;
  //   }
  // },

  // TODO finish this - reload the form when clickind End Session
  handleSessionVideoClosed(context: any, data: any) {
    // TODO implement activity log
    // console.warn("TODO WS response - handleSessionVideoClosed context.state=", context.state, "data=", data); // TODO Sessions
    if (context.state.session) {
      if (data.message) {
        Vue.prototype.$events.$emit("nice-toast", data.message, "success");
      }
      context.dispatch("fetchConferenceSession", { conferenceId: context.state.conference.id, sessionId: context.state.session.id });
      context.dispatch("fetchConferenceSessions", { conferenceId: context.state.conference.id });
    }
    if (context.state.sessions) {
      context.dispatch("fetchConferenceSessions", { conferenceId: context.state.conference.id });
    }
  },

  // -------------------- Uploaded files --------------------
  async fetchConferenceFiles(context, { conferenceId, filters }) {
    try {
      const response = await ManagementService.getConferenceFiles(conferenceId, filters);
      context.commit("setConferenceFiles", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async createConferenceFile(context, { conferenceId, data }) {
    try {
      const response = await ManagementService.createConferenceFile(conferenceId, data);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference file '%{ filename }' was added."), {
          first_name: data.first_name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  async updateConferenceFile(context, { conferenceId, data }) {
    try {
      const response = await ManagementService.updateConferenceFile(conferenceId, data);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference file '%{ filename }' was updated."), {
          first_name: data.first_name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  async deleteConferenceFile(context, { conferenceId, fileId }) {
    try {
      const response = await ManagementService.deleteConferenceFile(conferenceId, fileId);
      Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("Conference file was removed."), "success");
      return response;
    } catch (error) {
      throw error;
    }
  },

  // -------------------- Users --------------------
  async fetchConferenceUsers(context, { conferenceId, filters }) {
    try {
      const response = await ManagementService.getConferenceUsers(conferenceId, filters);
      context.commit("setConferenceUsers", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async createConferenceUser(context, { conferenceId, data }) {
    try {
      const response = await ManagementService.createConferenceUser(conferenceId, data);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference user '%{ first_name }' was added."), {
          first_name: data.first_name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  async updateConferenceUser(context, { conferenceId, data }) {
    try {
      const response = await ManagementService.updateConferenceUser(conferenceId, data);
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Conference user '%{ first_name }' was updated."), {
          first_name: data.first_name,
        }),
        "success"
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  async deleteConferenceUser(context, { conferenceId, userId }) {
    try {
      const response = await ManagementService.deleteConferenceUser(conferenceId, userId);
      Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("Conference user was removed."), "success");
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchFormOptions(context, conferenceId) {
    try {
      await context.dispatch("fetchAttendeeGroups", { conferenceId, filters: { no_page: true } });
      await context.dispatch("fetchConferenceStages", { conferenceId, filters: { no_page: true } });
      await context.dispatch("fetchConferenceSessions", { conferenceId, filters: { no_page: true } });
      await context.dispatch("fetchConferenceShowrooms", { conferenceId, filters: { no_page: true } });
    } catch (error) {
      throw error;
    }
  },
};

// ---------------------------------------------------------------- Model ----------------------------------------------------------------
const management = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default management;
