import { AppError } from "../error.service";
import RequestService from "../request.service";

const PollsService = {
  getConferencePolls: async function (conferenceId: number, filters: any) {
    try {
      let path = `/api/v1/conferences/${conferenceId}`;
      if (filters.stage) {
        path += `/stages/${filters.stage}/polls/details/`;
      } else if (filters.session) {
        path += `/sessions/${filters.session}/polls/details/`;
      } else if (filters.showroom) {
        path += `/showrooms/${filters.showroom}/polls/details/`;
      }
      const response = await RequestService.get(path);
      return response;
    } catch (error) {
      throw new AppError(error);
    }
  },

  pollVote: async function (conferenceId: number, filters: any, pollId: number, choiceId: number) {
    try {
      let path = `/api/v1/conferences/${conferenceId}`;
      if (filters.stage) {
        path += `/stages/${filters.stage}/polls/${pollId}/vote/`;
      } else if (filters.session) {
        path += `/sessions/${filters.session}/polls/${pollId}/vote/`;
      } else if (filters.showroom) {
        path += `/showrooms/${filters.showroom}/polls/${pollId}/vote/`;
      }
      const data = {
        choice: choiceId,
      };
      const response = await RequestService.post(path, data);
      return response;
    } catch (error) {
      throw new AppError(error);
    }
  },

  exportPolls: async function (conferenceId: number, selectedPolls: [any]) {
    try {
      const queryAppend = "id=" + selectedPolls.map((poll) => `${poll.id}`).join(",");
      const path = `/api/v1/management/conferences/${conferenceId}/polls/export/?${queryAppend}`;
      const response = await RequestService.getBlob(path);
      return response;
    } catch (error) {
      throw new AppError(error);
    }
  },

  getManagementPolls: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/polls/`;
      const response = await RequestService.get(path, filters);
      return response;
    } catch (error) {
      throw new AppError(error);
    }
  },

  getManagementPoll: async function (conferenceId: number, pollId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/polls/${pollId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  createManagementPoll: async function (conferenceId: number, data: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/polls/`;
      return await RequestService.post(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateManagementPoll: async function (conferenceId: number, data: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/polls/${data.id}/`;
      console.log(data);
      return await RequestService.put(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  _preparePoll(poll) {
    const data = new FormData();
    data.append("question", poll.question);
    data.append("private", poll.private);
    data.append("allow_multiple_answers", poll.allow_multiple_answers);
    data.append("show_votes_in_numbers", poll.show_votes_in_numbers);
    data.append("published_date", new Date(poll.published_date).toISOString());
    data.append("stage", poll.stage || "");
    data.append("session", poll.session || "");
    data.append("showroom", poll.showroom || "");
    data.append("choices", JSON.stringify(poll.choices));

    return data;
  },

  deleteManagementPoll: async function (conferenceId: number, pollId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/polls/${pollId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  publishManagementPoll: async function (conferenceId: number, pollId: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/polls/${pollId}/publish/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  unpublishManagementPoll: async function (conferenceId: number, pollId: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/polls/${pollId}/unpublish/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  openManagementPoll: async function (conferenceId: number, pollId: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/polls/${pollId}/open/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  closeManagementPoll: async function (conferenceId: number, pollId: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/polls/${pollId}/close/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },
};

export default PollsService;
