import Vue from "vue";
import MeetingService from "@/services/api/meetings.service";
import MeetingModel from "@/models/meetings.model";
import Router from "@/router";
import ErrorService from "@/services/error.service";
import Store from "@/store";
import NotificationModel from "@/models/notification.model";

interface MeetingsStateI {
  hasNewMeeting: boolean;
  loadingMeetings: boolean;
  meetings: MeetingModel[];
  meeting: MeetingModel | null;
  activeCallState: any;
}

// ---------------------------------------------------------------- State ----------------------------------------------------------------
const state: MeetingsStateI = {
  hasNewMeeting: false,
  loadingMeetings: false,
  meetings: [],
  meeting: null,
  activeCallState: null,
};

// ---------------------------------------------------------------- Getters ----------------------------------------------------------------
const getters = {
  hasNewMeetingMessage(): boolean {
    if (state.hasNewMeeting) {
      return true;
    }

    for (const index in state.meetings) {
      const meeting: MeetingModel = state.meetings[index];
      if (meeting.hasNewMessage) {
        return true;
      }
    }

    return false;
  },
};

// ---------------------------------------------------------------- Mutations --------------------------------------------------------------
const mutations = {
  setMeetings(state: MeetingsStateI, meetings: MeetingModel[]) {
    const unreadNotifications: NotificationModel[] = Store.getters["notifications/allUnreadNotifications"];
    meetings.forEach((meeting) => {
      const notification = unreadNotifications.find(
        (x) => x.objectId == meeting.id && ["NEW_MISSED_CALL", "NEW_MESSAGE"].includes(x.notificationType)
      );

      meeting.hasNewMessage = notification ? true : false;
    });
    state.meetings = meetings;
  },

  setMeeting(state: MeetingsStateI, meeting: MeetingModel) {
    const itemIndex = state.meetings.findIndex((p) => meeting.id == p.id);
    if (itemIndex >= 0) {
      state.meeting = meeting;
      Vue.set(state.meetings, itemIndex, meeting);
    } else {
      state.meetings.unshift(meeting);
    }
  },

  unsetMeeting(state: MeetingsStateI) {
    state.meeting = null;
  },

  setHasNewMeeting(state: MeetingsStateI, hasNewMeeting: boolean) {
    state.hasNewMeeting = hasNewMeeting;
  },

  cleanStore(state: MeetingsStateI) {
    state.meetings = [];
  },
};

// ---------------------------------------------------------------- Actions ----------------------------------------------------------------
const actions = {
  handleNewMessage(context: any, data: Record<string, any>) {
    const meeting = state.meetings.find((m: MeetingModel) => m.chat_route_key == data.chat_channel);
    if (meeting) {
      if (Number(Router.currentRoute.params.meetingId) != meeting.id) {
        (meeting as MeetingModel).hasNewMessage = true;
      }
    }
  },

  handleNewChatAttachment(context: any, data: Record<string, any>) {
    const meeting = state.meetings.find((m: MeetingModel) => m.chat_route_key == data.chat_channel);
    if (meeting) {
      if (Number(Router.currentRoute.params.meetingId) != meeting.id) {
        (meeting as MeetingModel).hasNewMessage = true;
        Vue.prototype.$events.$emit(
          "nice-toast",
          Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("New meeting attachment from '%{ firstname_lastname }'."), {
            firstname_lastname: data.initiator,
          }),
          "success"
        );
      }
    }
  },

  handleNewMeeting(context: any, meeting: MeetingModel) {
    Vue.prototype.$events.$emit(
      "nice-toast",
      Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("New meeting with '%{ firstname_lastname }' started."), {
        firstname_lastname: meeting.initiator.first_name + " " + meeting.initiator.last_name,
      }),
      "success"
    );
    context.commit("setHasNewMeeting", true);
    context.commit("setMeeting", meeting);
  },

  handleNotificationRead(context: any, notification: NotificationModel) {
    if (["NEW_MESSAGE"].includes(notification.notificationType)) {
      const meeting = context.state.meetings.find((m: MeetingModel) => m.id == notification.objectId);
      if (meeting) (meeting as MeetingModel).hasNewMessage = false;
    }
  },

  handleAllNotificationsRead(context: any) {
    context.state.meetings.forEach((meeting: MeetingModel) => {
      meeting.hasNewMessage = false;
    });
  },

  handleVideoStarted(context, data) {
    // Happens on a user calling you. Should do nothing but give you a notification, unless you are already in a call
    if (Router.currentRoute.query.call === "active") {
      if (data.object_id !== context.state.meeting.id) {
        // IF user is attempting call to an already active user, send response
        context.dispatch("stopVideo", { conferenceId: data.conference, attendeeId: data.data.senderId, type: "inCall" });
      }
      return;
    }
    // Add notification
    Store.dispatch("notifications/handleNotificationUpdated", data);
  },

  handleVideoEnded(context, data) {
    // Happens on a user leaving call and you are in that call
    Store.dispatch("notifications/handleNotificationDeleted", "call-" + data.senderId);
    if (Router.currentRoute.query.call !== "active" || data.meeting_id != Router.currentRoute.params.meetingId) {
      return;
    }
    if (data.type === "rejected") {
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("%{ caller } has refused your call"), {
          caller: data.sender,
        }),
        "warning"
      );
    } else if (data.type === "inCall") {
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("%{ caller } is in an active call"), {
          caller: data.sender,
        }),
        "warning"
      );
    } else {
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("%{ caller } has ended the call"), {
          caller: data.sender,
        }),
        "warning"
      );
    }
    context.state.activeCallState = data.type;
    Router.replace({ query: { video: Router.currentRoute.query.video } });
  },

  setActiveCallState(context: any, value) {
    context.state.activeCallState = value;
  },

  async fetchMeetings(context: any, { conferenceId, filters }) {
    state.loadingMeetings = true;
    try {
      const response = await MeetingService.getAll(conferenceId, filters);
      context.commit("setMeetings", response);
      return response;
    } catch (error) {
      throw error;
    } finally {
      state.loadingMeetings = false;
    }
  },

  async fetchMeeting(context: any, { conferenceId, meetingId }) {
    if (context.state.meeting && context.state.meeting.id == meetingId) {
      return context.state.meeting;
    } else {
      try {
        const response = await MeetingService.get(conferenceId, meetingId);
        context.commit("setMeeting", response);
        return response;
      } catch (error) {
        throw error;
      }
    }
  },

  async startNewMeeting(context: any, { conferenceId, attendeeId }) {
    try {
      const response = await MeetingService.startNew(conferenceId, attendeeId);
      context.commit("setMeeting", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchToken(context: any, { conferenceId, attendeeId }) {
    try {
      const response = await MeetingService.startVideo(conferenceId, attendeeId);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async stopVideo(context: any, { conferenceId, attendeeId, type }) {
    try {
      const response = await MeetingService.endVideo(conferenceId, attendeeId, type);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchAttachmentHistory(context: any, { chatRouteKey }) {
    try {
      const response = await MeetingService.getAttachments(chatRouteKey);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async goToMeeting(context: any, { conferenceId, attendeeId }) {
    try {
      const response = await MeetingService.startNew(conferenceId, attendeeId);
      context.commit("setMeeting", response);
      Router.push({ name: "app.conference.meeting", params: { meetingId: response.id } });
    } catch (error) {
      ErrorService.handleError(error);
    }
  },
};

// ---------------------------------------------------------------- Model ----------------------------------------------------------------
const meetings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default meetings;
