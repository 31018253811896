import Vue from "vue";

interface ChatStateI {
  hasMoreMessages: boolean;
  hasNewChatMessage: boolean;
  unreadMessages: number;
  chatEnabled: boolean;
  currentChatRouteKey: string;
}

// ---------------------------------------------------------------- State ----------------------------------------------------------------
const state: ChatStateI = {
  hasMoreMessages: false,
  hasNewChatMessage: false,
  unreadMessages: 0,
  chatEnabled: true,
  currentChatRouteKey: "",
};

// ---------------------------------------------------------------- Getters ----------------------------------------------------------------
const getters = {};

// ---------------------------------------------------------------- Mutations --------------------------------------------------------------
const mutations = {
  cleanStore(state: ChatStateI) {
    state.hasMoreMessages = false;
    state.hasNewChatMessage = false;
    state.unreadMessages = 0;
  },

  setHasNewChatMessage(state: ChatStateI, hasNewMessage: boolean) {
    state.hasNewChatMessage = hasNewMessage;
  },

  setChatEnabled(state: ChatStateI, isEnabled: boolean) {
    state.chatEnabled = isEnabled;
  },

  setUnreadMessages(state: ChatStateI, unreadMessages: number) {
    state.unreadMessages = unreadMessages;
  },

  incrementUnreadMessages(state: ChatStateI) {
    state.unreadMessages++;
  },

  setCurrentChatRouteKey(state: ChatStateI, route: string) {
    state.currentChatRouteKey = route;
  },
};

// ---------------------------------------------------------------- Actions ----------------------------------------------------------------
const actions = {
  handleChatBanned() {
    Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("You are banned from the chat!"), "error");
  },
};

// ---------------------------------------------------------------- Module ----------------------------------------------------------------
const chat = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default chat;
